import {
  CoreBox, CoreClasses, CoreH6, CoreMenu, CoreTypographyBody1, MENU_ITEM
} from "@wrappid/core";

import PrescriptionTopBar from "./PrescriptionTopBar";

function PrescriptionRightDrawerContent(props) {

  const {
    prescriptionNavData,
    prescriptionClinic,
    prescription,
    language,
    allClinics,
    FocusForm
  } = props;
	
  /**
   * Right Drawer Menu Click- Focus Specific Form
   *
   * @param {*} item
   */
  const OnMenuClick = (item) => {
    FocusForm(item.name);
  };

  return (
    <>
      <CoreBox
        styleClasses={[CoreClasses.DISPLAY.SM.NONE, CoreClasses.PADDING.P1]}
      >
        <PrescriptionTopBar
          prescription={prescription}
          prescriptionNavData={prescriptionNavData}
          HandleTemplateChange={this.HandleTemplateChange}
          template={this.state.template}
          basic={this.props.basic}
          prescriptionClinic={prescriptionClinic}
          allClinics={allClinics}
          onLanguageChange={this.HandleLanguageChange}
          language={language}
        />
      </CoreBox>
      
      <CoreBox accordionSummery={
        <CoreTypographyBody1>Quick Navigation</CoreTypographyBody1>
      }>
        <CoreMenu
          OnMenuClick={OnMenuClick}
          open={this.props.rightMenuOpen}
          menu={this.props.mainMasterData
            ?.find((tmp) => tmp.name === "prescription")
            ?.Children?.filter((tmp) => !tmp.isVisible)
            ?.map((tmp) => {
              return {
                type: MENU_ITEM,
                ...tmp
              };
            })}
        />
      </CoreBox>
      
      <CoreBox accordionSummery={
        <CoreTypographyBody1>Helper</CoreTypographyBody1>
      }>
        <CoreH6>Coming Soon</CoreH6>
      </CoreBox>
    </>
  );
}

export default PrescriptionRightDrawerContent;