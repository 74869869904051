import { __IconTypes, MENU_SEPERATOR } from "@wrappid/core";

import modulesMenusRegistry from "../modules/modules.menus.registry";

export const MenusRegistry = {
  ...modulesMenusRegistry,
  helpAndSupportMenu: [
    {
      icon : "support",
      id   : "ContactSupport",
      label: "Contact Support",
      route: "_request_support"
    },
    { type: MENU_SEPERATOR },
    {
      icon : "tips_and_updates",
      id   : "FeatureRequest",
      label: "Feature Request",
      route: "_request_feature"
    },
    {
      icon : "auto_fix_high",
      id   : "EnhancementRequest",
      label: "Enhancement Request",
      route: "_request_enhancement"
    },
    { type: MENU_SEPERATOR },
    {
      icon : "bug_report",
      id   : "reportBug",
      label: "Report Bug",
      route: "_report_bug"
    }
  ],
  quickAddMenu: [
    {
      icon: {
        icon: "fa-user-injured",
        type: "FONTAWESOME_V5_SOLID_ICON"
      },
      id    : "createPatient",
      label : "Add Patient",
      params: "?create=true",
      route : "patient_manage"
    },
    {
      icon: {
        icon: "fa-file-prescription",
        type: __IconTypes.FONTAWESOME_V5_SOLID_ICON
      },
      id   : "createPrescription",
      label: "Create Prescription",
      route: "newPrescription"
    },
    {
      icon: {
        icon: "fa-calendar-check",
        type: __IconTypes.FONTAWESOME_V5_SOLID_ICON
      },
      id    : "manage_appointment?create=true",
      label : "Schedule Appointment",
      params: "?create=true",
      route : "appointment_manage"
    }
  ]
};
