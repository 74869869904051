export const GET_MED_DATA_API = "/getMedData";

export const APP_ROLES = {
  ASSISTANT: "assistant",
  DOCTOR   : "doctor",
  PATIENT  : "patient"
};

export const prescriptionDataStructure = {
  AdvicedMedicines  : {},
  AdvisedTests      : {},
  Complaints        : {},
  Diagnosis         : {},
  Followups         : {},
  Guidelines        : {},
  Histories         : {},
  PrescriptionVitals: {},
  Procedures        : {}
};
