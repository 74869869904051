import React, { useContext, useEffect } from "react";

import {
  AppContainerLayout,
  CORE_DIALOG_TYPES,
  CoreDialogContext,
  CoreLayoutItem,
  CoreRoutesContext,
  coreUseNavigate,
  coreUseParams
} from "@wrappid/core";
import { WrappidDataContext } from "@wrappid/styles";
import { useDispatch, useSelector } from "react-redux";

import PrescriptionMain from "./PrescriptionMain";
import { clearPrescription, setPrescriptionReducer } from "../actions/prescription.action";
import { PRESCRIPTION_MODE } from "../constants/prescription.constant";
import { PRESCRIPTION_MODE_CHANGE, RESET_PRESCRIPTION_FLAGS } from "../types/prescription.types";

/**
 * Prepare error text for missing profile data
 * @param {*} missingData 
 * @returns 
 */
function getErrorText(missingData) {
  let mData = {
    email    : "email",
    firstName: "first name",
    gender   : "gender",
    phone    : "phone number",
    photoUrl : "profile picture",
    regDate  : "registration date",
    regNo    : "registration no."
  };
  let errorText = "Your profile is not complete, add the following details: ";
  let mDataKeys = Object.keys(missingData);

  for (let i = 0; i < mDataKeys.length; i++) {
    errorText += mData[mDataKeys[i]] + ", ";
  }
  return errorText;
}

/**
 * Prescription page can be accessed in the following ways
 * 1. Create new prescription
 * 2. Edit existing prescription
 * 3. View existing prescription
 * 
 * @todo
 * categorically we need
 * new(with(with/without appointment)/without patient)
 * existing(complete/incomplete)
 * 
 * @returns Prescription component
 */
export default function Prescription() {
  const dispatch = useDispatch();
  const navigate = coreUseNavigate();
  const params = coreUseParams();
  
  const {
    id: prescriptionIDparam = null,
    withPatient = false,
    withAppointment = false,
    mode = PRESCRIPTION_MODE.CREATE
  } = params;

  // eslint-disable-next-line etc/no-commented-out-code
  const routeRegistry = useContext(CoreRoutesContext);
  const dialogContext = useContext(CoreDialogContext);
  const { config: appConfig } = useContext(WrappidDataContext);

  const {
    prescription,
    downloadPrescriptionError,
    downloadPrescriptionSuccess,
    downloadPrescriptionMsg
  } = useSelector(
    (state) => state.prescription
  );

  const { id: prescriptionID = null, isCompleted: prescriptionCompleted = false } = prescription || {};

  // eslint-disable-next-line etc/no-commented-out-code
  const { /* quotient = 0, */ missingData } = useSelector(state => state.profile?.completeness?.report);

  const [readyForEditor, setReadyForEditor] = React.useState(false);

  /**
   * Check if profile completed
   */
  const checkProfileCompleted = () => {
    // eslint-disable-next-line etc/no-commented-out-code
    /* return quotient && quotient === 100; */
    return true;
  };

  /**
     * @todo review required
     * 
     * This will help us to block create prescription
     */
  const openProfileNotCompleteDialog = () => {
    dialogContext?.setDialog({
      doneButton: () => {
        navigate(routeRegistry?.profile);
      },
      doneButtonLabel: "Okay",
      noCancelButton : true,
      showDialog     : true,
      subtitle       : getErrorText(missingData),
      title          : "You profile is not complete!",
      type           : "info"
    });
  };

  /**
     * This dialog will open if a prescription in edit mode and not completed
     */
  const clearPrescriptionWarningDialog = () => {
    dialogContext.setDialog({
      cancelButton: () => {
        dispatch({ type: RESET_PRESCRIPTION_FLAGS });
        setReadyForEditor(true);
      },
      cancelButtonLabel: "Continue",
      doneButton       : () => {
        dispatch(clearPrescription(prescriptionID, withPatient, withAppointment));
        setReadyForEditor(true);
      },
      doneButtonLabel: "Clear",
      showDialog     : true,
      subtitle       : "Would you like you continue or start fresh",
      title          : "You already have a prescription !",
      type           : "info"
    });
  };

  useEffect(() => {
    /**
       * If profile not completed then we will not allow to create prescription
       */
    if (!checkProfileCompleted()) {
      openProfileNotCompleteDialog();
    } else {
      /**
         * @todo
         * Prescription loading
         * Case 1: prescription preview
         * Case 2: prescription edit
         * Case 3: prescription create
         * Case 4: prescription create with patient
         * Case 5: prescription create with appointment
         */

      // Launch Clear Prescription Warning Dialog
      if ((prescriptionID && !prescriptionCompleted) && (
        (prescriptionID !== prescriptionIDparam)
        || !prescriptionIDparam
      )) {
        clearPrescriptionWarningDialog();
      } else {
        dispatch({ type: RESET_PRESCRIPTION_FLAGS });
        setReadyForEditor(true);
      }
    }
  }, []);

  useEffect(() => {
    // Set prescription mode
    if (mode && Object.keys(PRESCRIPTION_MODE).includes(mode)) {
      dispatch({ payload: mode, type: PRESCRIPTION_MODE_CHANGE });
    }
  }, [mode]);

  useEffect(() => {
    if (downloadPrescriptionSuccess) {
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded successfully!",
        title     : "Success",
        type      : CORE_DIALOG_TYPES?.SUCCESS
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }
    if (downloadPrescriptionError) {
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded error!",
        title     : "Error",
        type      : CORE_DIALOG_TYPES?.FAILURE
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }
  }, [downloadPrescriptionError, downloadPrescriptionSuccess]);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <>
          {readyForEditor && <PrescriptionMain
            prescriptionIDparam={prescriptionIDparam}
            withPatient={withPatient}
            withAppointment={withAppointment}
            appConfig={appConfig} />}
        </>
      </CoreLayoutItem>
    </>
  );
}
