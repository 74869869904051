import {
  CoreBox,
  CoreClasses,
  CoreForm,
  CoreGrid,
  CoreTypographyBody1,
  CoreTypographyCaption,
  FORM_EDIT_MODE,
  FORM_IDS,
  FORM_VIEW_MODE
} from "@wrappid/core";
import { useSelector } from "react-redux";

import PrescriptionGenratedData from "./PrescriptionGenratedData";
import PrescriptionMedicine from "./PrescriptionMedicine";

export default function PrescriptionForms({
  formKey,
  formDesign,
  gridProps,
  prescription,
  prescriptionNavData,
  GetInitData,
  vitalsForm,
  prescriptionClinic,
  formRef,
  onFormFocus,
  medicineFunctions
}) {
  const allClinics = useSelector(
    (state) => state?.selectOptions?.options?.clinics?.data
  );

  const renderForm = ({ name: formID }) => {
    let renderableOB = null;

    switch (formID) {
      case FORM_IDS.__PRESCRIPTION_MEDICINE:
        renderableOB = (
          <CoreGrid gridProps={formDesign?.design || {}}>
            <PrescriptionMedicine
              mode={FORM_VIEW_MODE}
              allowEdit={prescription && prescription.isCompleted ? false : true}
              initData={GetInitData(FORM_IDS.__PRESCRIPTION_MEDICINE)}
              formRef={formRef}
              onMountRead={false}
              onFormFocus={onFormFocus}
              // eslint-disable-next-line etc/no-commented-out-code
              // theme={theme}
              AddMedicine={medicineFunctions?.AddMedicine}
              SubmitMeds={medicineFunctions?.SubmitMeds}
              onEditMedicineNote={
                medicineFunctions?.onEditMedicineNote
              }
              onChangeMedGeneric={
                medicineFunctions?.onChangeMedGeneric
              }
              OnMedClear={medicineFunctions?.OnMedClear}
              advMedicines={medicineFunctions?.advMedicines}
              formId={FORM_IDS.__PRESCRIPTION_MEDICINE}
            />
          </CoreGrid>
        );
        break;

      case FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE:
        renderableOB = prescriptionNavData && prescriptionNavData?.Person?.id > 0 ? (
          <CoreGrid>
            <CoreBox gridProps={{ gridSize: 12 }}>
              <CoreForm
                allowDelete={false}
                formId={
                  FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                }
                coreId={
                  FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                }
                gridProps={formDesign?.design || {}}
                initData={GetInitData(
                  FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                )}
                mode={FORM_VIEW_MODE}
                allowEdit={false}
                formRef={formRef}
                onMountRead={false}
              />
            </CoreBox>

            <CoreBox gridProps={{ gridSize: 12 }}>
              <PrescriptionGenratedData
                prescription={prescription}
              />
            </CoreBox>
          </CoreGrid>
        ) : (
          <CoreBox styleClasses={[CoreClasses.SHADOW.LARGE, CoreClasses.PADDING.P2]}>
            <CoreTypographyCaption>Select patient to proceed</CoreTypographyCaption>
                
            <CoreForm
              formId={FORM_IDS.__SEARCH_PATIENT}
              coreId={FORM_IDS.__SEARCH_PATIENT}
              gridProps={formDesign?.design || {}}
              mode={FORM_EDIT_MODE}
              allowEdit={
                prescription && prescription.isCompleted
                  ? false
                  : true
              }
              formRef={formRef}
            />
          </CoreBox>
        );
        break;

      case FORM_IDS.__PRESCRIPTION_CLINIC:
        renderableOB = prescriptionClinic ? (
          <CoreForm
            allowDelete={false}
            allowEdit={false}
            formId={FORM_IDS.__PRESCRIPTION_CLINIC}
            coreId={FORM_IDS.__PRESCRIPTION_CLINIC}
            gridProps={formDesign?.design || {}}
            initData={GetInitData(
              FORM_IDS.__PRESCRIPTION_CLINIC
            )}
            mode={FORM_VIEW_MODE}
            formRef={formRef}
            onMountRead={false}
          />
        ) : allClinics && allClinics.length > 0 ? (
          <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_CENTER]}>
            <CoreTypographyBody1>No Clinic selected</CoreTypographyBody1>
          </CoreBox>
        ) : (
          <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_CENTER]}>
            <CoreTypographyBody1>No Clinic found</CoreTypographyBody1>
          </CoreBox>
        );
        break;

      case FORM_IDS.__PRESCRIPTION_VITALS:
        renderableOB = <CoreForm
          key={"vital-" + formKey}
          allowDelete={false}
          coreId={FORM_IDS.__PRESCRIPTION_VITALS}
          formId={FORM_IDS.__PRESCRIPTION_VITALS}
          gridProps={formDesign?.design || {}}
          formJson={vitalsForm}
          initData={GetInitData(FORM_IDS.__PRESCRIPTION_VITALS)}
          // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
          mode={FORM_EDIT_MODE}
          allowEdit={
            (prescription && prescription.isCompleted) ||
              !prescriptionNavData?.Person
              ? false
              : true
          }
          formRef={formRef}
          onMountRead={false}
          onFormFocus={onFormFocus}
        />;
        break;
    
      default:
        renderableOB = <CoreForm
          allowDelete={false}
          coreId={formDesign.name}
          formId={formDesign.name}
          gridProps={formDesign?.design || {}}
          initData={GetInitData(formDesign.name)}
          // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
          mode={FORM_VIEW_MODE}
          allowEdit={
            (prescription && prescription.isCompleted) ||
              !prescriptionNavData?.Person
              ? false
              : true
          }
          formRef={formRef}
          onMountRead={false}
          onFormFocus={onFormFocus}
        />;
        break;
    }

    return renderableOB;
  };

  return (
    <CoreBox gridProps={gridProps} styleClasses={[CoreClasses.PADDING.P1]}>
      {renderForm(formDesign)}
    </CoreBox>
  );
}
